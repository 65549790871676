/* eslint-disable no-console */
/* eslint indent: [1, 2, {"SwitchCase": 1}]*/

'use strict';

const App = {};

App.ready = (callback) => {
  if (document.readyState != 'loading') callback();
  else document.addEventListener('DOMContentLoaded', callback);
};

App.ready(() => {
  App.init();
});

App.init = function () {
  // console.log('App is ready!');
  this.initUserButton();
  this.initPasswordChangeForm();
  this.initBackToTopButton();
};

App.initUserButton = function () {
  const $el = document.querySelector('#js-user-button');
  if (!$el) return;

  $el.addEventListener('click', (e) => {
    e.preventDefault();
    const $target = document.querySelector('#js-user-menu');
    $target.classList.toggle('hidden');
  });
};

App.initPasswordChangeForm = function () {
  const $el = document.querySelector('#js-reset-password-form');
  if (!$el) return;

  const $form = $el.querySelector('#js-reset-password-form form');
  const $submitButton = $el.querySelector('#js-submit-button');
  const $password = $el.querySelector('#password');
  const $passwordConfirm = $el.querySelector('#password_confirm');
  const $passwordStrengthFeedback = $el.querySelector('#js-password-strength-feedback');
  const $passwordConfirmFeedback = $el.querySelector('#js-password-confirm-feedback');

  $submitButton.addEventListener('click', (e) => {
    e.preventDefault();
    const bool = checkPasswordStrength() && checkPasswordConfirm();
    if (bool) {
      $form.submit();
    } else {
      showPasswordStrengthFeedback(bool);
      showPasswordConfirmFeedback(bool);
      $password.addEventListener('change', checkPasswords, false);
      $passwordConfirm.addEventListener('change', checkPasswords, false);
      $password.addEventListener('keyup', checkPasswords, false);
      $passwordConfirm.addEventListener('keyup', checkPasswords, false);
    }
  });

  const checkPasswords = function () {
    checkPasswordStrength();
    checkPasswordConfirm();
  };

  const checkPasswordStrength = function () {
    const password = $password.value;
    let bool = true;
    // 8 characters
    if (password.length < 8) {
      bool = false;
    }
    // 1 uppercase
    if (!password.match(/[A-Z]/)) {
      bool = false;
    }
    // 1 lowercase
    if (!password.match(/[A-z]/)) {
      bool = false;
    }
    // 1 digit
    if (!password.match(/\d/)) {
      bool = false;
    }
    showPasswordStrengthFeedback(bool);
    return bool;
  };

  const checkPasswordConfirm = function () {
    const bool = $password.value === $passwordConfirm.value;
    showPasswordConfirmFeedback(bool);
    return bool;
  };

  const showPasswordStrengthFeedback = function (bool) {
    if (bool) {
      $passwordStrengthFeedback.classList.add('hidden');
    } else {
      $passwordStrengthFeedback.classList.remove('hidden');
    }
  };

  const showPasswordConfirmFeedback = function (bool) {
    if (bool) {
      $passwordConfirmFeedback.classList.add('hidden');
    } else {
      $passwordConfirmFeedback.classList.remove('hidden');
    }
  };

  const enableSubmitButton = function (bool) {
    if (bool) {
      $submitButton.removeAttribute('disabled');
    } else {
      $submitButton.setAttribute('disabled', 'disabled');
    }
  };
};

App.initBackToTopButton = function () {
  const $el = document.querySelector('#js-back-to-top-button');
  if (!$el) return;

  const trackScroll = function () {
    const scrolled = window.pageYOffset;
    const coords = document.documentElement.clientHeight;

    if (scrolled > coords) {
      console.log('show button');
      $el.classList.remove('hidden');
      $el.goTopBtn.classList.add('block');
    }
    if (scrolled < coords) {
      console.log('hide button');
      $el.classList.add('hidden');
      $el.classList.remove('block');
    }
  };

  const scrollBack = function () {
    if (window.pageYOffset > 0) {
      window.scrollBy(0, -80);
      setTimeout(scrollBack, 0);
    }
  };

  window.addEventListener('scroll', trackScroll);
  $el.addEventListener('click', scrollBack);
};
